<template>
    <div style="min-height: 100vh;background-color: #F8F9FA;">
        <!-- 商品信息 -->
        <div class="good">
            <div class="goodTop">
                <img src="../../../images/order/poster.png" alt="" class="goodPic">
                <div class="goodInfo">
                    <div style="display:flex;flex-direction: column;">
                        <span>{{ commodityInfo.commodityName }}</span>
                        <span class="originalPrice">原价 ¥{{ commodity.originalPrice }}</span>
                    </div>
                    <span class="price">¥{{ commodity.discountedPrice }}</span>
                </div>
            </div>
            <div class="goodVersion">
                <div class="wrapperTitle">
                    <div class="line"></div>
                    已选版本
                </div>
                <div class="versionBox">
                    <div class="version">
                        {{ commodity.name }}
                    </div>
                </div>
            </div>
        </div>

        <div class="health" @click="healthSurvey">健康调查问卷
            <van-icon name="arrow" size="14px" color="#999999" />
        </div>

        <!-- 换购商品 -->
        <div class="exchangeGood" v-if="additionalInfo != undefined">
            <div class="exchangeGoodInfo">
                <img :src="osspath + additional.image" alt="" class="exchangeGoodPic">
                <div class="exchangeGoodContent">
                    <div style="width: 197px;font-size: 14px;color: #333333;">
                        {{ additionalInfo.additionalName }}
                    </div>
                    <div class="display_flex">
                        <span style="color:#E02020 ;">￥{{ additional.discountedPrice }}</span>
                        <span
                            style="font-size: 12px;font-weight: 400;color: #999999;margin-left: 8px;text-decoration: line-through">￥{{
                                additional.originalPrice
                            }}</span>
                    </div>
                </div>
            </div>
            <div class="chooseVersion">
                <span style="font-size: 14px;color: #333333;">款式选择</span>
                <span style="font-size: 14px;color: #333333;">{{ additional.name }}</span>
            </div>
        </div>

        <!-- 收货信息 -->
        <div class="mailLocation" v-if="additionalInfo != undefined">
            <div class="wrapperTitle" style="padding:0 0 16px 0;">
                <div class="line"></div>
                收货信息
            </div>
            <div class="mailBox">
                <div class="mailTitle">收货人</div>
                <div>{{ receivingInfo.userName }}</div>
            </div>
            <div class="mailBox">
                <div class="mailTitle">联系电话</div>
                <div>{{ receivingInfo.phone }}</div>
            </div>
            <div class="mailBox">
                <div class="mailTitle">所在地区</div>
                <div>{{ receivingInfo.province + '-' + receivingInfo.city + '-' + receivingInfo.area }}</div>
            </div>
            <div class="mailBox" style="margin-bottom: 0;">
                <div class="mailTitle">详细地址</div>
                <div class="wordBreak">{{ receivingInfo.detailedAddress }}</div>
            </div>
        </div>

        <!-- 商品金额 -->
        <div class="goodPrice" :style="{ marginTop: additionalInfo != undefined ? '12px' : '0' }">
            <div class="wrapperTitle" style="justify-content: space-between;padding: 0;">
                <div class="display_flex">
                    <div class="line"></div>
                    <span>商品金额</span>
                </div>
                <span>￥{{ totalAmount }}</span>
            </div>
            <div class="priceBox">
                <div>
                    <span>{{ commodity.name }}</span>
                    <span style="color: #999999;margin-left: 16px;">x 1</span>
                </div>
                <div>￥{{ commodity.discountedPrice }}</div>
            </div>
            <div class="priceBox" style="margin-bottom: 0;" v-if="additionalInfo != undefined">
                <div>
                    <span>{{ additionalInfo.additionalName }}</span>
                    <span style="color: #999999;margin-left: 16px;">x 1</span>
                </div>
                <div style="color:#E02020">￥{{ additional.discountedPrice }}</div>
            </div>
        </div>

        <div style="height:92px;width:100%;"></div>
        <div class="bottom" style="padding:10px 16px;">
            <div class="submit">
                <div style="color: #333333;line-height: 22px;">
                    <span>实付款</span>
                    <span style="color:#E02020;margin-left: 4px;">￥{{ totalAmount }}</span>
                </div>
                <button class="submitBottom" @click="payHandle">提交订单</button>
            </div>
        </div>
    </div>
</template>
<script>
import { Toast } from 'vant'
import { osspath } from "../../../util/config"
import { throttle } from '../../../util/util'
import OrderApi from "../api/index"
const orderApi = new OrderApi()
export default {
    data() {
        return {
            id: '',
            commodityInfo: {},  //商品信息
            commodity: {},
            additionalInfo: {},  //加购商品信息
            additional: {},
            healthQuestionnaire: {},
            receivingInfo: {},
            totalAmount: 0,
            osspath: osspath,
            flag: 0,
        }
    },
    created() {
        // 获取订单信息并渲染
        let orderInfo = JSON.parse(localStorage.getItem('order'))
        this.id = orderInfo.id
        this.commodityInfo = orderInfo.commodityInfo
        this.commodity = orderInfo.commodityInfo.commodity
        this.additionalInfo = orderInfo.additionalInfo

        // 勾选了换购商品
        if (this.additionalInfo != undefined) {
            this.additional = orderInfo.additionalInfo.additional
            this.receivingInfo = orderInfo.receivingInfo
            // 计算商品总金额：
            this.totalAmount = this.add(this.additional.discountedPrice, this.commodity.discountedPrice)
        } else {
            // 计算总金额
            this.totalAmount = this.commodity.discountedPrice
        }
    },
    mounted() {
    },
    computed: {
    },
    methods: {
        // 去健康调查问卷页面
        healthSurvey() {
            localStorage.setItem('isReadonly', 'true')
            this.$router.push('/orderHealth')
        },
        // 防抖动
        payHandle: throttle(function () {
            this.onSubmit()
        }, 2000),
        //  提交订单
        onSubmit() {
            let params = {
                id: this.id,
            }
            let that = this
            orderApi.pay(params).then((res) => {
                let data = res.data
                function onBridgeReady() {
                    WeixinJSBridge.invoke(
                        'getBrandWCPayRequest', {
                        "appId": data.appId,     //公众号ID，由商户传入     
                        "timeStamp": data.timeStamp,         //时间戳，自1970年以来的秒数     
                        "nonceStr": data.nonceStr, //随机串     
                        "package": data.packageVal,
                        "signType": data.signType,         //微信签名方式：     
                        "paySign": data.paySign //微信签名 
                    },
                        function (res) {
                            if (res.err_msg == "get_brand_wcpay_request:ok") {
                                // 使用以上方式判断前端返回,微信团队郑重提示：
                                //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠
                                Toast.loading({
                                    message: '加载中...',
                                    forbidClick: true,
                                    loadingType: 'spinner'
                                });
                                that.doItPerSecond()
                            } else {
                                Toast("支付失败")
                            }
                        });
                }
                if (typeof WeixinJSBridge == "undefined") {
                    if (document.addEventListener) {
                        document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
                    } else if (document.attachEvent) {
                        document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
                        document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
                    }
                } else {
                    onBridgeReady();
                }
            })
        },
        // 检测是否支付成功 5次及以上就返回支付失败结果
        doItPerSecond() {
            //循环执行，每隔1秒钟执行一次 1000
            let t1 = setInterval(findreturn, 1000);
            const that = this;
            function findreturn() {
                that.flag++;
                if (that.flag >= 6) {
                    // console.log("请求超时......");
                    that.flag = 0;
                    //去掉定时器的方法
                    clearInterval(t1);
                    that.clearStore()
                    // 清除loading
                    Toast.clear()
                    Toast('支付失败')
                } else {
                    orderApi.payCallback(that.id).then((res) => {
                        if (res.code == 0) {
                            //去掉定时器的方法
                            clearInterval(t1);
                            that.clearStore()
                            // 清除loading
                            Toast.clear()
                            that.$router.replace("/paySuccess")
                        }
                    })
                }
            }
        },
        // 清空本地存储信息
        clearStore() {
            // 清空订单信息
            localStorage.removeItem('orderInfo')
            localStorage.removeItem('order')
            // 清空健康调查问卷
            localStorage.removeItem('healthSurvey')
            localStorage.removeItem('isHealthAchieve')
            // 清空用户协议
            localStorage.removeItem('agreement')
            localStorage.removeItem('userRights')
            localStorage.removeItem('isReadonly')
        },
        add(num1, num2) {
            var m = Math.pow(10, 2); //放大10的2次方倍  100
            return (num1 * m + num2 * m) / m;  //(0.1*100 + 0.2*100）/100
        },
    }
}
</script>
<style lang="scss" scoped>
.goodTop {
    display: flex;
    align-items: center;
    width: 100%;
    height: 152px;
    background: #FFFFFF;
    padding: 16px;
    box-sizing: border-box;

    .goodPic {
        width: 90px;
        height: 120px;
        border-radius: 8px;
        border: 1px solid #E6E6E6;
        overflow: hidden;
        flex-shrink: 0
    }
}

.goodInfo {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 10px;
    color: #333333;


    .originalPrice {
        font-weight: 400;
        color: #CCCCCC;
        text-decoration: line-through;
        margin-top: 8px;
    }

    .price {
        font-size: 20px;

        color: #E02020;
        line-height: 28px;
    }
}

.goodVersion {
    margin-top: 12px;
    width: 100%;
    background-color: #fff;



    .versionBox {
        display: flex;
        flex-wrap: wrap;
        padding: 0 16px 4px 16px;
    }

    .version {
        padding: 6px 10px;
        background: #F2F8FF;
        border-radius: 4px;

        font-size: 14px;

        color: #1477FD;
        margin: 0 20px 12px 0;
    }
}

.wrapperTitle {
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    background: #FFFFFF;
    display: flex;
    align-items: center;

    color: #333333;
    line-height: 22px;
    font-size: 16px;

    .line {
        width: 2px;
        height: 10px;
        background: #1477FD;
        border-radius: 1px;
        margin-right: 8px;
    }
}

.health {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;

    color: #1477FD;

    .healthButton {
        width: 96px;
        height: 40px;
        background: #1477FD;
        border-radius: 8px;

        color: #FFFFFF;
        text-align: center;
        line-height: 40px;
    }
}

.exchangeGood {
    height: 167px;
    background: #FFFFFF;
    padding: 16px;
    box-sizing: border-box;


    .chooseVersion {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 11px;
    }
}

.exchangeGoodInfo {
    display: flex;
    align-items: center;
    padding-bottom: 17px;
    border-bottom: 1px solid #E6E6E6;


    .exchangeGoodPic {
        width: 90px;
        height: 90px;
        border-radius: 8px;
        border: 1px solid #E6E6E6;
    }

    .exchangeGoodContent {
        margin-left: 16px;
        height: 90px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
}

.mailLocation {
    margin-top: 12px;
    padding: 16px;
    background-color: #fff;


    .mailBox {
        margin-bottom: 16px;
        display: flex;
        font-size: 14px;

        color: #333333;
    }

    .mailTitle {
        width: 86px;
        flex-shrink: 0
    }
}

.goodPrice {
    margin-top: 12px;
    background-color: #fff;
    padding: 16px;

    .priceBox {
        font-size: 16px;

        color: #333333;
        line-height: 22px;
        margin-top: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.submit {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.submitBottom {
    width: 124px;
    height: 40px;
    background: #1477FD;
    border-radius: 8px;
    font-size: 14px;

    color: #FFFFFF;
    text-align: center;
    line-height: 40px;
    border: none;
}
</style>
